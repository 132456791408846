import MainBackground from "components/UI/MainBackground";
import Content from "components/Content/Content";

import './assets/style/App.scss'

function App() {

    return (
        <>
            <div id="backdrop-container"></div>
            <div id="modal-container"></div>
            <MainBackground>
                <Content />
            </MainBackground>
        </>
    );
}

export default App;