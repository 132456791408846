import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { OpenseaLogo, TwitterLogo, EnvelopIcon } from 'components/UI/Icons';

const HeaderNavigationDesktop = (props) => {
    const { nav } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [flashMessageVisible, setFlashMessageVisible] = useState(false);

    const dropdownOpenHandler = () => {
        setIsOpen(s => !s);
    };

    const iconClickJandler = (url) => {
        window.open(url, '_blank');
    }

    const handleEnvelopClick = () => {
        navigator.clipboard.writeText('crenfinite@protonmail.com');
        setFlashMessageVisible(true)

        setTimeout(() => setFlashMessageVisible(false), 3000)
    }

    const animation = {
        initial: { opacity: 0, y: -15 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 15 },
    };

    return (
        <nav>
            <ul>
                {nav.map((item) =>
                    <li key={item.id}>
                        <NavLink to={item.href}>
                            {item.text}
                        </NavLink>
                    </li>
                )}
                <li>
                    <div onClick={dropdownOpenHandler} >
                        <span className={isOpen ? 'active' : ''}>Contacts</span>
                    </div>
                    <AnimatePresence>
                    { isOpen ?
                        <motion.div variants={animation} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.25}}>
                            <div id="dropdown-container">
                                <div className='card-inner'>
                                    <div className='table'>
                                        <div onClick={() => { iconClickJandler('https://opensea.io/crenfinite') }}>
                                            <OpenseaLogo />
                                        </div>
                                        <div onClick={() => { iconClickJandler('https://twitter.com/crenfinite') }}>
                                            <TwitterLogo />
                                        </div>
                                        <div onClick={handleEnvelopClick} title="Copy email to clipboard">
                                            <EnvelopIcon />
                                        </div>
                                    </div>
                                    <div className='flash-message-container'>
                                        <AnimatePresence>
                                            { flashMessageVisible ? (
                                                <motion.div variants={animation} initial="initial" animate="animate" exit="exit">
                                                    <span>Email copied to clipboard!</span>
                                                </motion.div>
                                            ) : null}
                                        </AnimatePresence>
                                    </div>
                                </div>
                            </div>
                        </motion.div> 
                    : null
                    }
                    </AnimatePresence>
                </li>
            </ul>
        </nav>
    );
};

export default HeaderNavigationDesktop;