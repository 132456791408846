import React, { useState, useEffect, useCallback } from "react";

import PortfolioList from "components/Content/Main/Portfolio/PortfolioList";
import CategoryChanger from "components/Content/Main/Portfolio/CategoryChanger";
import GalleryModal from "components/Content/Main/Portfolio/GalleryModal";
import PageTitle from 'components/UI/PageTitle';
import AnimatedPage from "components/UI/AnimatedPage";
import PortfolioItems from "constants/PortfolioItems";

const Portfolio = () => {
    const [portfolioList, setPortfolioList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');

    const categories = ['All', 'Cards', 'Logos'];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState('');

    const changeCategoryHandler = (c) => {
        if (c !== '' && c !== null && c !== undefined) {
            setSelectedCategory(c);
        }
    };

    const filterItems = useCallback((category = 'All') => {
        if (category !== 'All') {
            setPortfolioList(PortfolioItems.filter(item => item.category === category));
        } else {
            setPortfolioList(PortfolioItems);
        }
    }, []);

    useEffect(() => {
        filterItems(selectedCategory);
    }, [selectedCategory, filterItems]);

    const modalShowHandler = () => setIsOpen(true);

    const modalCloseHandler = () => {
        setIsOpen(false);
    };
    

    const itemClickHandler = (id) => {
        setSelectedItemIndex(portfolioList.findIndex(x => x.id === id));
        modalShowHandler();
    };

    useEffect(() => {
        document.title = "Crenfinite | Portfolio"
     }, []);

    return (
        <>
            <PageTitle title="Portfolio" />
            <AnimatedPage>
                <CategoryChanger
                    categories={categories}
                    selectedCategory={selectedCategory}
                    changeCategory={changeCategoryHandler}
                />
                <PortfolioList
                    itemList={portfolioList}
                    itemClick={itemClickHandler}
                />
            </AnimatedPage>
            <GalleryModal
                isOpen={isOpen}
                openModal={modalShowHandler}
                closeModal={modalCloseHandler}
                portfolioList={portfolioList}
                selectedItemIndex={selectedItemIndex}
                setSelectedItemIndex={setSelectedItemIndex}
            />
        </>
    );
}

export default Portfolio;