import React from 'react';

const CategoryChangerDesktop = (props) => {
    const {
        categories,
        selectedCategory,
        changeCategory,
    } =props;

    return (
        <ul>
            { categories.map((c, index) => (
                <li 
                    key={index}
                    onClick={(e) => { changeCategory(e.target.innerText); }}
                    className={selectedCategory === c ? 'active' : ''}
                >
                    {c}
                </li>
            )) }
        </ul>
    );
};

export default CategoryChangerDesktop;