import React from "react";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion'

import Header from "components/Content/Header/Header";
import Home from "Pages/Home";
import Portfolio from "Pages/Portfolio";
// import AboutUs from "pages/AboutUs";
// import Contacts from 'pages/Contacts'

const Content = () => {
    const location = useLocation();

    return (
        <div id="content">
            <Header />
            <main>
                <AnimatePresence exitBeforeEnter initial={false}>
                    <Routes key={location.pathname} location={location} >
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/portfolio" element={<Portfolio />} />
                        {/* <Route exact path="/about-us" element={<AboutUs />} />
                        <Route path="/contact" element={<Contacts />} /> */}
                        <Route path="*" element={<Home />} />
                    </Routes>  
                </AnimatePresence>
            </main>
        </div>
    );
}

export default Content;
