import React from "react";

const Wrapper = props => {
    const {
        direction,
        justifyContent,
        alignItems,
    } = props;

    return (
        <div className="wrapper" style={
            {
                flexDirection: direction,
                justifyContent,
                alignItems,
            }
        }> 
            {props.children}
        </div>
    );
};

export default Wrapper;