import React from "react";

// import MainBackgroundImage from "assets/images/main-background.jpg"

const MainBackground = (props) => {
    // const image = { backgroundImage: `url(${<MainBackgroundImage />})` };

    return (
        <div id="main-background" >{props.children}</div>
    );
}

export default MainBackground;