import React from 'react';

import Wrapper from 'components/UI/Wrapper';
const WhatWeDoListElement = (props) => {
    const {
        img,
        title,
        text
    } = props

    return (
        <div className='info-list-element' >
            <Wrapper direction="row" alignItems="flex-start" justifyContent="flex-start">
                <Wrapper direction="column" alignItems="flex-start" justifyContent="flex-start">
                    {img}
                </Wrapper>
                <Wrapper direction="column" alignItems="flex-start" justifyContent="flex-start">
                    <h3>
                        {title}
                    </h3>
                    <p>
                        {text}
                    </p>
                </Wrapper>
            </Wrapper>
        </div>
    );
};

export default WhatWeDoListElement;