import React, { useState } from 'react';

import HeaderNavigationMobile from 'components/Content/Header/HeaderNavigationMobile';
import HeaderNavigationDesktop from 'components/Content/Header/HeaderNavigationDesktop';


const Navigation = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const resizeHandler = () => {
        setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', resizeHandler);

    const nav = [
        {
            id: 0,
            text: 'Home',
            href: '/',
        },
        {
            id: 2,
            text: 'Portfolio',
            href: '/portfolio',
        },
    ];

    return windowWidth > 767 ? <HeaderNavigationDesktop nav={nav}/> : <HeaderNavigationMobile nav={nav} />;
}

export default Navigation;



