import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faXmark } from '@fortawesome/free-solid-svg-icons'

const GalleryModal = (props) => {
    const {
        isOpen,
        closeModal,
        portfolioList,
        selectedItemIndex,
        setSelectedItemIndex
    } = props;

    const selectedItem = portfolioList[selectedItemIndex] ? portfolioList[selectedItemIndex] : {};

    const prevItemClickHandler = () => {
        if (selectedItemIndex === 0) {
            setSelectedItemIndex(portfolioList.length - 1);
        } else {
            setSelectedItemIndex(s => s - 1);
        }
    };

    const nextItemClickHandler = () => {
        if (selectedItemIndex === portfolioList.length - 1) {
            setSelectedItemIndex(0);
        } else {
            setSelectedItemIndex(s => s + 1);
        }
    };

    return (
        <Modal show={isOpen} onHide={closeModal} backdrop="true" keyboard={false} id="gallery-modal">
            <div className="modal-wrapper">
                <div className="modal-header">
                    <div className="modal-title" onSelectCapture={() => false} onMouseDown={() => false}>
                        {selectedItem.text}
                    </div>
                    <div className="modal-close-container">
                        <div className="button" onClick={closeModal}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                </div>
                <div className="modal-image-container">
                    <div style={{backgroundImage: `url(${selectedItem.img})`}}/>
                </div>
                <div className="modal-actions">
                    <div className="prev-button" onClick={prevItemClickHandler}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </div>
                    <div className="next-button" onClick={nextItemClickHandler}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default GalleryModal;