// ADS

// LOGOS
import Logo1 from 'assets/images/portfolio/logos/1.jpg';
import Logo2 from 'assets/images/portfolio/logos/2.jpg'
import Logo3 from 'assets/images/portfolio/logos/3.jpg';
import Logo4 from 'assets/images/portfolio/logos/4.jpg';
import Logo5 from 'assets/images/portfolio/logos/5.jpg';
import Logo6 from 'assets/images/portfolio/logos/6.jpg';

// CARDS 
import Card1 from 'assets/images/portfolio/cards/1.jpg';
import Card2 from 'assets/images/portfolio/cards/2.jpg';
import Card3 from 'assets/images/portfolio/cards/3.jpg';
import Card4 from 'assets/images/portfolio/cards/4.jpg';
import Card5 from 'assets/images/portfolio/cards/5.jpg';
import Card6 from 'assets/images/portfolio/cards/6.jpg';

// FLYERS

const PortfolioItems = [
    {
        id: 0,
        text: 'Emtent',
        customer: 'Emtent',
        category: 'Logos',
        img: Logo1,
        alt: 'Emtent',
        description: ""
    },
    {
        id: 1,
        text: 'Trembley',
        customer: 'Trembley',
        category: 'Logos',
        img: Logo2,
        alt: 'Trembley',
        description: ""
    },
    {
        id: 2,
        text: 'Click',
        customer: 'Click',
        category: 'Logos',
        img: Logo3,
        alt: 'Click',
        description: ""
    },
    {
        id: 3,
        text: 'Share',
        customer: 'Share',
        category: 'Logos',
        img: Logo4,
        alt: 'Share',
        description: ""
    },
    {
        id: 4,
        text: 'CHNL',
        customer: 'CHNL',
        category: 'Logos',
        img: Logo5,
        alt: 'CHNL',
        description: ""
    },
    {
        id: 5,
        text: 'Ebanda',
        customer: 'Ebanda',
        category: 'Logos',
        img: Logo6,
        alt: 'Ebanda',
        description: ""
    },
    {
        id: 6,
        text: 'DMI',
        customer: 'DMI',
        category: 'Cards',
        img: Card1,
        alt: 'DMI',
        description: ""
    },
    {
        id: 7,
        text: 'HQ',
        customer: 'HQ',
        category: 'Cards',
        img: Card2,
        alt: 'HQ',
        description: ""
    },
    {
        id: 8,
        text: 'Th.i.n',
        customer: 'Th.i.n',
        category: 'Cards',
        img: Card3,
        alt: 'Th.i.n',
        description: ""
    },
    {
        id: 9,
        text: 'Bellylicious',
        customer: 'Bellylicious',
        category: 'Cards',
        img: Card4,
        alt: 'Bellylicious',
        description: ""
    },
    {
        id: 10,
        text: 'Scube',
        customer: 'Scube',
        category: 'Cards',
        img: Card5,
        alt: 'Scube',
        description: ""
    },
    {
        id: 11,
        text: 'Novaro',
        customer: 'Novaro',
        category: 'Cards',
        img: Card6,
        alt: 'Novaro',
        description: ""
    },
];

export default PortfolioItems;