import React from "react";
import { AnimatePresence, motion } from 'framer-motion';

const PortfolioList = (props) => {
    const {
        itemList,
        itemClick,
     } = props;

    const animation = {
        animate: i => ({
          opacity: 1,
          y: 0,
          transition: {
            delay: i * 0.2,
            duration: 0.35,
          },
        }),
        initial: {
            opacity: 0,
            y: 50,
        },
        exit: i => ({
            opacity: 0,
            y: 50,
            transition: {
              duration: 0.35,
              delay: i * 0.2,
            },
          }),
      }

    return (
        <section id="portfolio-list">
            <div className="items-container">
                <ul>
                    <AnimatePresence initial={false}>
                    { itemList.map((item, index) => (
                        <motion.li
                            key={index}
                            custom={index}
                            variants={animation}
                            animate="animate"
                            initial="initial"
                            exit="exit"
                        >
                            <div className="item-container" onClick={() => {itemClick(item.id)}}>
                                <div className="image-container-outer">
                                    <div className="image-container" style={{backgroundImage: `url(${item.img})`}}></div>
                                </div>
                                <div className="text-container">{item.text}</div>
                            </div>
                        </motion.li>
                    )) }
                    </AnimatePresence>
                </ul>
            </div>
        </section>
    );
};

export default PortfolioList;