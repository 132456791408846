import React from "react";

import Navigation from "./HeaderNavigation";

const Header = () => {
    return (
        <header>
            <Navigation />
        </header>
    );
}

export default Header;