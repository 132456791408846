import React, { useEffect } from "react";

import Introduction from "components/Content/Main/Introduction/Introduction";
import WhatWeDo from "components/Content/Main/WhatWeDo/WhatWeDo";

import AnimatedPage from "components/UI/AnimatedPage";

const Home = () => {
    useEffect(() => {
        document.title = "Crenfinite | Home"
     }, []);

    return (
        <AnimatedPage>
            <Introduction />
            <WhatWeDo />
        </AnimatedPage>
    );
};

export default Home;