import React from "react";
import { motion } from 'framer-motion'

const animation = {
    initial: { opacity: 0, },
    animate: { opacity: 1, },
    exit: { opacity: 0, },
};

const PageTitle = (props) => {
    const { title } = props;

    return (
        <section id="page-title">
            <motion.h1 variants={animation} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.25}}>{title}</motion.h1>
        </section>
    );
};

export default PageTitle;