import React, { useState } from "react";

import CategoryChangerDesktop from "components/Content/Main/Portfolio/CategoryChangerDesktop";
import CategoryChangerMobile from "components/Content/Main/Portfolio/CategoryChangerMobile";

const CategoryChanger = (props) => {
    const {
        categories,
        selectedCategory,
        changeCategory,
    } = props;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const resizeHandler = () => {
        setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', resizeHandler);

    return (
        <section id="category-changer">
            { windowWidth > 500 
                    ? <CategoryChangerDesktop
                        categories={categories}
                        selectedCategory={selectedCategory}
                        changeCategory={changeCategory}
                    />
                    : <CategoryChangerMobile
                        categories={categories}
                        selectedCategory={selectedCategory}
                        changeCategory={changeCategory}
                    />
            }
        </section>
    );
};

export default CategoryChanger;