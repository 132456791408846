import { motion } from 'framer-motion'

const animation = {
    initial: { opacity: 0, x: 25 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -25 },
};

const AnimatedPage = (props) => {
    return (
        <motion.div variants={animation} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.25}}>
            {props.children}
        </motion.div>
    );
};

export default AnimatedPage;