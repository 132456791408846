import React from "react";
import ReactTypingEffect from 'react-typing-effect';
import { Container, Row, Col } from 'react-bootstrap';

import { Logo } from "components/UI/Icons";
import Wrapper from "components/UI/Wrapper";

const Introduction = () => {
    const _CONTENT = [
        "logos.",
        "flyers.",
        "ads.",
        "branding.",
        "value.",
        "infinite."
    ];

    return (
        <section id="introduction">
            <Container>
                <Row>
                    <Col lg={6} md={4} xs={12} className="image-container">
                        <Wrapper justifyContent="center">
                            <Logo />
                        </Wrapper>
                    </Col>
                    <Col lg={6} md={8} xs={12} >
                        <Wrapper direction="column" alignItems="center" justifyContent="center">
                            <h1>C<span>renfinite</span></h1>
                            <div className="text">
                                <span> Taking projects to infinity and beyond. </span>
                                <br />
                                <ReactTypingEffect
                                    staticText={"We create "}
                                    text={_CONTENT}
                                    speed={150}
                                    eraseSpeed={105}
                                    eraseDelay={1000}
                                    typingDelay={500}
                                />
                            </div>

                            <div className="action-controls">

                            </div>
                        </Wrapper>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Introduction;