import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const CategoryChangerMobile = (props) => {
    const {
        categories,
        selectedCategory,
        changeCategory,
    } =props;

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => setIsOpen(s => !s);

    const listItemClickHandler = (e) => {
        changeCategory(e.target.innerText)
        toggleIsOpen();
    };

    const animation = {
        animate: {
          opacity: 1,
          height: 'max-content',
          y: 0,
          transition: {
            duration: 0.2,
          }
        },
        initial: {
            opacity: 0,
            height: 0,
            y: -10,
        },
        exit: {
            opacity: 0,
            height: 0,
            y: 10,
            transition: {
                duration: 0.2,
                delay: 0.1
              }
          },
    };

    const animationLi = {
        animate: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.05,
            delay: 0.1,
          }
        },
        initial: {
            y: 5,
            opacity: 0,
        },
        exit: {
            opacity: 0,
            y: 5,
            transition: {
                duration: 0.05,
              }
        },
      }

    return (
        <>
            <div className="mobile-container">
                <div className={`selected-category-container ${ isOpen ? 'active' : '' }`} onClick={toggleIsOpen}>
                    {selectedCategory}
                </div>
                <div className="categories-container">
                    <AnimatePresence exitBeforeEnter initial={true}>
                        { isOpen ? 
                            <motion.ul
                                key="ul"
                                variants={animation}
                                animate="animate"
                                initial="initial"
                                exit="exit"
                            >
                                <>
                                { categories.map((c, index) => (
                                    <>
                                    { selectedCategory !== c ?
                                        <>
                                            <motion.li 
                                                variants={animationLi}
                                                animate="animate"
                                                initial="initial"
                                                exit="exit"
                                                key={index}
                                                onClick={(e) => { listItemClickHandler(e) }}
                                            >
                                                {c}
                                            </motion.li> 
                                            </>
                                    : null } 
                                    </>
                                )) }
                                </>
                            </motion.ul>
                        : null }
                    </AnimatePresence>
                </div>
            </div>
        </>
    );
};

export default CategoryChangerMobile;