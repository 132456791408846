import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { OpenseaLogo, TwitterLogo, EnvelopIcon } from 'components/UI/Icons';

const HeaderNavigationMobile = (props) => {
    const { nav } = props;

    const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
    const [flashMessageVisible, setFlashMessageVisible] = useState(false);

    const toggleMobileNav = () => {
        setMobileNavIsOpen(s => {
            if (s) {
                 document.body.style.overflow = 'unset';
                return false;
            } else {
                 document.body.style.overflow = 'hidden';
                return true;
            }
        })
    };

    const listItemClickHandler = () => {
        toggleMobileNav();
    };

    const iconClickJandler = (url) => {
        window.open(url, '_blank');
    }

    const handleEnvelopClick = () => {
        navigator.clipboard.writeText('crenfinite@protonmail.com');
        setFlashMessageVisible(true)

        setTimeout(() => setFlashMessageVisible(false), 3000)
    }

    const animation = { 
        initial: { opacity: 0, x: 15 },
        animate: i => ({
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.45,
            },
        }),
        exit: i => ({
            opacity: 0,
            x: 15,
            transition: {
              duration: 0.45,
              delay: 0.4,
            },
        }),
        
    };

    const animationLi = {
          initial: {
              opacity: 0,
              x: 50,
          },
          animate: i => ({
            opacity: 1,
            x: 0,
            transition: {
              delay: i * 0.4,
              duration: 0.35,
            },
          }),
          exit: i => ({
              opacity: 0,
              x: 50,
              transition: {
                duration: 0.35,
                delay: i * 0.2,
              },
            }),
    }

    return (
        <nav>
            <div className={`hamburger-icon-container ${mobileNavIsOpen ? 'active' : ''}`} onClick={toggleMobileNav}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <AnimatePresence exitBeforeEnter initial={false}>
                { mobileNavIsOpen ?
                <>
                    <motion.ul variants={animation} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.45}}>
                        {nav.map((item, index) =>
                            <AnimatePresence exitBeforeEnter initial={false}>
                                <motion.li
                                    key={item.id}
                                    onClick={listItemClickHandler}
                                    custom={index}
                                    variants={animationLi}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    transition={{ duration: 0.25 }}
                                >
                                    <NavLink to={item.href}>
                                        {item.text}
                                    </NavLink>
                                </motion.li>
                            </AnimatePresence>
                        )}
                        <div id="dropdown-container">
                            <div className='card-inner'>
                                <div className='table'>
                                    <div onClick={() => { iconClickJandler('https://opensea.io/crenfinite') }}>
                                        <OpenseaLogo />
                                    </div>
                                    <div onClick={() => { iconClickJandler('https://twitter.com/crenfinite') }}>
                                        <TwitterLogo />
                                    </div>
                                    <div onClick={handleEnvelopClick} title="Copy email to clipboard">
                                        <EnvelopIcon />
                                    </div>
                                </div>
                                <div className='flash-message-container'>
                                        <AnimatePresence>
                                            { flashMessageVisible ? (
                                                <motion.div variants={animation} initial="initial" animate="animate" exit="exit">
                                                    <span>Email copied to clipboard!</span>
                                                </motion.div>
                                            ) : null}
                                        </AnimatePresence>
                                    </div>
                            </div>
                        </div>
                    </motion.ul> 
                </> : null }
            </AnimatePresence>
        </nav>
    );
};

export default HeaderNavigationMobile;