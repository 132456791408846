import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { CustomerIcon, ResearchIcon, NetworkIcon, CryptoIcon } from "components/UI/Icons";
import WhatWeDoListElement from "./WhatWeDoListElement";

const WhatWeDo = () => {
    const whatWeDo = [
        {
            img: <CryptoIcon />,
            title: "Cryptotech",
            text: "We are true believers in the crypto world. Crenfinite utilizes the Ethereum blockchain to process payments and transfer designs.",
        },
        {
            img: <CustomerIcon />,
            title: "Customer relations",
            text: "We pursue excellent cooperation with our clients. In order to achieve maximum efficiency and satisfaction, each and every project is separated into production phases, we consult with the client when each stage is completed. Therefore, we provide revision during the whole process of production.",
        },
        {
            img: <ResearchIcon />,
            title: "Research",
            text: "Every project is different. Before working with a customer, we thoroughly research the niché of their brand. With such a method the final product will be unique and representative.",
        },
        {
            img: <NetworkIcon />,
            title: "Networking",
            text: "Our network consists of multiple artists from all over the globe. We can cover all corners of graphic design from full scale branding to smaller projects like Twitch emotes.",
        },
    ];

    return (
        <section id="what-we-do">
            <h2>What we do</h2>
            <div className="info-list-container">
                <Container fluid='lg'>
                    <Row>
                        {
                            whatWeDo.map((element, index) => (
                                <Col xs={12} md={12} lg={6} key={index}>
                                    <WhatWeDoListElement
                                        img={element.img}
                                        title={element.title}
                                        text={element.text}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default WhatWeDo;